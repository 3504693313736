import "./game-component-selector.css";

const GameComponentCheckbox = ({
  entityType,
  name,
  selected,
  onCheckFunction,
}: {
  entityType: string;
  name: string;
  selected: boolean;
  onCheckFunction: (name: string, isIncluded: boolean) => void;
}) => {
  return (
    <div className="component-checkbox-and-label">
      <div className="row">
        <input
          className="col checkbox"
          type="checkbox"
          checked={selected}
          onChange={() => {
            onCheckFunction(name, selected);
          }}
        />
      </div>
      <div className="row game-component-pic">
        <img
          className="col-10 pic-image"
          src={"/images/" + entityType + "/" + name + ".png"}
          alt={name}
          width=""
        />
      </div>
      <div className="row game-component-label">
        <div className="col">{name}</div>
      </div>
    </div>
  );
};

export const GameComponentSelector = ({
  availableGameComponents,
  onCheckFunction,
}: {
  availableGameComponents: { name: string; include: boolean }[];
  onCheckFunction: (name: string, isIncluded: boolean) => void;
}) => {
  return (
    <div className="col-10 col-lg-8 checkbox-selector">
      <div className="row radio-header">
        Include Expansions (Spirits/Adversaries/Scenarios)
      </div>
      <div className="row component-checkboxes">
        <div className="col">
          <div className="row  game-component-options">
            {availableGameComponents.map(function (mt, i) {
              return (
                <div
                  className="col-8 col-sm-4 col-md-2 game-component-option"
                  key={i}
                >
                  <GameComponentCheckbox
                    entityType="gameComponentOptions"
                    name={mt.name}
                    selected={mt.include}
                    onCheckFunction={onCheckFunction}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
