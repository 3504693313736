import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {
  SPIRITS,
  ADVERSARIES,
  MAP_TYPE,
  SCENARIOS,
  GAME_COMPONENT_OPTIONS,
  MAPS,
} from "../../info/constants";
import { GameSetup } from "../../types/game-setup.type";
import "./game-randomizer.css";
import "rc-slider/assets/index.css";
import { GeneralSetup } from "../../types/general-setup-type";
import "bootstrap/dist/css/bootstrap.min.css";
import { FilterGameOptionsButton } from "./modal/filter-options-modal.component";
import {
  getDifficultyRange,
  updateBasedOnGameComponents,
} from "../../functions/helper-functions.component";
import { FilterOptionType } from "../../types/filter-option.type";
import { GameComponent } from "../../types/game-component.type";
import {
  randomizeMaps,
  randomizeSpirits,
} from "../../functions/randomizers.component";
import { notEnoughSpirits } from "./validation/number-spirits-check.component";
import { ResultsView } from "./results-view/results-view.component";
import { PlayerNumberSelector } from "./set-parameters-view/elements/num-players-selector.component";
import { GameComponentSelector } from "./set-parameters-view/elements/game-component-selector.component";
import { GameTypeSelector } from "./set-parameters-view/elements/game-type-selector.component";
import { MapTypeSelector } from "./set-parameters-view/elements/map-type-selector.component";
import { getAllCombinations } from "../../functions/get-combinations.component";
import { filterAllOptionsWrapper } from "../../functions/filter-options.component";
import { ErrorStripe } from "./set-parameters-view/elements/error-stripe.component";
import { DifficultySelector } from "./set-parameters-view/elements/difficulty-selector.component";
import { notEnoughMaps } from "./validation/number-maps-check.component";

export default class Randomizer extends Component<
  {},
  {
    hasResult: boolean;
    gameSetup: GameSetup;
    availableMapTypes: { name: string; include: boolean }[];
    availableGameComponents: { name: string; include: boolean }[];
    availableSpirits: FilterOptionType[];
    availableScenarios: FilterOptionType[];
    availableAdversaries: FilterOptionType[];
    availableMaps: {
      name: string;
      gameComponent: GameComponent;
      mapType: string;
      include: boolean;
    }[];
    includeJEMaps: boolean;
    difficultyRange: number[];
    noValidGame: {
      noMapType: boolean;
      notEnoughSpirits: boolean;
      notEnoughMaps: boolean;
      invalidDifficultyRange: boolean;
    };
    validOptions: GeneralSetup[];
  }
> {
  constructor(hasResult: boolean, gameSetup: GameSetup) {
    super(hasResult, gameSetup);

    this.onChangeRandomized = this.onChangeRandomized.bind(this);
    this.onChangeNumPlayers = this.onChangeNumPlayers.bind(this);
    this.onChangeSliderRange = this.onChangeSliderRange.bind(this);
    this.onChangeGameComponent = this.onChangeGameComponent.bind(this);
    this.onChangeMapType = this.onChangeMapType.bind(this);
    this.onChangeJEMapsIncluded = this.onChangeJEMapsIncluded.bind(this);
    this.onChangeGameType = this.onChangeGameType.bind(this);

    this.state = {
      hasResult: false,
      availableMapTypes: MAP_TYPE.map((mapType, i) => {
        return { name: mapType.name, include: true };
      }),
      availableGameComponents: GAME_COMPONENT_OPTIONS.map((option, i) => {
        return { name: option.name, include: true };
      }),
      availableSpirits: SPIRITS.map((spirit, i) => {
        return {
          name: spirit.name,
          gameComponent: spirit.gameComponent as GameComponent,
          include: true,
        };
      }),
      availableAdversaries: ADVERSARIES.map((adv, i) => {
        return {
          name: adv.name,
          gameComponent: adv.gameComponent as GameComponent,
          include: true,
        };
      }),
      availableMaps: MAPS.map((map, i) => {
        return {
          name: map.name,
          gameComponent: map.gameComponent as GameComponent,
          mapType: map.mapType,
          include: true,
        };
      }),
      includeJEMaps: true,
      availableScenarios: SCENARIOS.filter(
        (scen) =>
          ![
            "THIRD WAVE",
            "FOURTH WAVE",
            "FIFTH WAVE",
            "SIXTH WAVE",
            "SEVENTH WAVE",
          ].includes(scen.name)
      ).map((scen, i) => {
        return {
          name: scen.name,
          gameComponent: scen.gameComponent as GameComponent,
          include: true,
        };
      }),
      difficultyRange: [0, 16],
      validOptions: getAllCombinations(),
      noValidGame: {
        noMapType: false,
        notEnoughSpirits: false,
        notEnoughMaps: false,
        invalidDifficultyRange: false,
      },
      gameSetup: {
        players: [
          {
            name: "",
            spirit: "",
            board: "",
          },
          {
            name: "",
            spirit: "",
            board: "",
          },
        ],
        generalSetup: {
          gameType: "JAGGED EARTH",
          adversary: "",
          adversaryLevel: 0,
          scenario: "",
          mapType: "",
          difficulty: 0,
        },
      },
    };
  }

  onChangeNumPlayers(numPlayers: number) {
    let newPlayers = [];
    for (let i = 1; i <= numPlayers; i++) {
      newPlayers.push({
        name: "",
        spirit: "",
        board: "",
      });
    }
    let currentState = { ...this.state };
    currentState.gameSetup.players = newPlayers;
    currentState.noValidGame.notEnoughSpirits = notEnoughSpirits(
      currentState.availableSpirits,
      currentState.gameSetup.players
    );
    currentState.noValidGame.notEnoughMaps = notEnoughMaps(
      currentState.availableMaps,
      currentState.availableMapTypes,
      currentState.gameSetup.players
    );
    this.setState(currentState);
  }

  onChangeGameType(gameType: string) {
    let currentState = { ...this.state };
    currentState.gameSetup.generalSetup.gameType = gameType;
    let filteredOptions = filterAllOptionsWrapper(currentState, false);
    currentState.difficultyRange = getDifficultyRange(filteredOptions);
    this.setState(currentState);
  }

  onChangeRandomized(randomize: boolean) {
    let currentState = { ...this.state };
    currentState.hasResult = randomize;
    if (currentState.hasResult) {
      let gameOptions = filterAllOptionsWrapper(currentState, true);
      let randomGameSetup =
        gameOptions[Math.floor(Math.random() * gameOptions.length)];
      currentState.gameSetup.generalSetup = randomGameSetup;
      let randomSpirits = randomizeSpirits(
        currentState.gameSetup.players.length,
        currentState.availableSpirits
      );
      let randomMaps = randomizeMaps(
        currentState.gameSetup.players.length,
        currentState.gameSetup.generalSetup.mapType,
        currentState.availableMaps
          .filter(
            (map) =>
              map.include &&
              map.mapType === currentState.gameSetup.generalSetup.mapType
          )
          .map((map, i) => {
            return {
              name: map.name,
            };
          })
      );
      for (let n = 0; n < currentState.gameSetup.players.length; n++) {
        currentState.gameSetup.players[n].spirit = randomSpirits[n];
        currentState.gameSetup.players[n].board = randomMaps[n];
      }
    }
    this.setState(currentState);
  }

  onChangeJEMapsIncluded(newVal: boolean) {
    let currentState = { ...this.state };
    currentState.includeJEMaps = newVal;
    currentState.availableMaps
      .filter((x) => x.gameComponent === "JAGGED EARTH")
      .forEach((e) => {
        if (!newVal) {
          e.include = newVal;
        } else {
          let isMapTypeAlreadyIncluded = currentState.availableMapTypes.filter(
            (mapType) => mapType.name === e.mapType
          )[0].include;
          if (isMapTypeAlreadyIncluded) {
            e.include = newVal;
          }
        }
      });
    currentState.noValidGame.notEnoughMaps = notEnoughMaps(
      currentState.availableMaps,
      currentState.availableMapTypes,
      currentState.gameSetup.players
    );
    this.setState(currentState);
  }

  onChangeMapType(name: string, newVal: boolean) {
    let currentState = { ...this.state };
    currentState.availableMaps
      .filter((x) => x.mapType === name)
      .forEach((e) => {
        if (
          !(
            e.gameComponent === "JAGGED EARTH" &&
            !currentState.includeJEMaps &&
            newVal
          )
        ) {
          e.include = newVal;
        }
      });
    currentState.availableMapTypes
      .filter((x) => x.name === name)
      .forEach((e) => {
        e.include = newVal;
      });
    let filteredOptions = filterAllOptionsWrapper(currentState, false);
    currentState.difficultyRange = getDifficultyRange(filteredOptions);
    currentState.noValidGame.noMapType =
      filteredOptions.length > 0 ? false : true;
    if (filteredOptions.length === 0) {
      currentState.includeJEMaps = false;
    }
    this.setState(currentState);
  }

  onChangeGameComponent(name: string) {
    let currentState = { ...this.state };
    let isIncluded = true;
    currentState.availableGameComponents
      .filter((x) => x.name === name)
      .forEach((e) => {
        e.include = !e.include;
        isIncluded = e.include;
      });
    currentState.availableSpirits = updateBasedOnGameComponents(
      currentState.availableSpirits,
      name,
      isIncluded
    );
    currentState.availableAdversaries = updateBasedOnGameComponents(
      currentState.availableAdversaries,
      name,
      isIncluded
    );
    currentState.availableScenarios = updateBasedOnGameComponents(
      currentState.availableScenarios,
      name,
      isIncluded
    );
    let filteredOptions = filterAllOptionsWrapper(currentState, false);
    currentState.noValidGame.noMapType =
      filteredOptions.length > 0 ? false : true;
    currentState.noValidGame.notEnoughSpirits = notEnoughSpirits(
      currentState.availableSpirits,
      currentState.gameSetup.players
    );
    currentState.difficultyRange = getDifficultyRange(filteredOptions);
    currentState.noValidGame.invalidDifficultyRange = false;
    this.setState(currentState);
  }

  onChangeSliderRange(rangeValues: number[]) {
    let currentState = { ...this.state };
    currentState.difficultyRange = rangeValues;
    let filteredOptions = filterAllOptionsWrapper(currentState, true);
    currentState.noValidGame.invalidDifficultyRange =
      filteredOptions.length > 0 ? false : true;
    this.setState(currentState);
  }

  render() {
    return (
      <div>
        {this.state.hasResult === false && (
          <div className="reset-view">
            <div className="row">
              <ErrorStripe
                noMapType={this.state.noValidGame.noMapType}
                notEnoughSpirits={this.state.noValidGame.notEnoughSpirits}
                notEnoughMaps={this.state.noValidGame.notEnoughMaps}
                invalidDifficultyRange={
                  this.state.noValidGame.invalidDifficultyRange
                }
              />
            </div>
            <div className="row number-players-selector">
              <PlayerNumberSelector
                players={this.state.gameSetup.players}
                onChangeFunction={this.onChangeNumPlayers}
              />
            </div>
            <div className="row game-map-selector">
              <div className="col-lg-4 game-type-selector">
                <GameTypeSelector
                  generalSetup={this.state.gameSetup.generalSetup}
                  onChangeGameType={this.onChangeGameType}
                />
              </div>
              <div className="col-lg-4 map-type-selector">
                <MapTypeSelector
                  mapTypes={this.state.availableMapTypes}
                  includeJEMaps={this.state.includeJEMaps}
                  onSelectMapType={this.onChangeMapType}
                  onSelectJEMaps={this.onChangeJEMapsIncluded}
                />
              </div>
            </div>
            <div className="row game-component-selector">
              <GameComponentSelector
                availableGameComponents={this.state.availableGameComponents}
                onCheckFunction={this.onChangeGameComponent}
              />
            </div>

            <div className="row expandable-options">
              <div className="col-sm-5 col-md-3 filter-option">
                <FilterGameOptionsButton
                  entityType="spirits"
                  initialOptions={this.state.availableSpirits}
                  onSave={(newAvailableSpirits) => {
                    let newValidation = this.state.noValidGame;
                    newValidation.notEnoughSpirits = notEnoughSpirits(
                      newAvailableSpirits,
                      this.state.gameSetup.players
                    );
                    this.setState({
                      ...this.state,
                      availableSpirits: newAvailableSpirits,
                      noValidGame: newValidation,
                    });
                  }}
                ></FilterGameOptionsButton>
                <br />
                {this.state.availableSpirits.filter((s) => s.include).length}/
                {this.state.availableSpirits.length} OPTIONS INCLUDED
              </div>
              <div className="col-sm-5 col-md-3 filter-option">
                <FilterGameOptionsButton
                  entityType="adversaries"
                  initialOptions={this.state.availableAdversaries}
                  onSave={(newAvailableAdversaries) => {
                    const currentState = {
                      ...this.state,
                      availableAdversaries: newAvailableAdversaries,
                    };
                    const newFilteredOptions = filterAllOptionsWrapper(
                      currentState,
                      false
                    );
                    const newDifficultyRange =
                      getDifficultyRange(newFilteredOptions);
                    this.setState({
                      ...this.state,
                      availableAdversaries: newAvailableAdversaries,
                      difficultyRange: newDifficultyRange,
                    });
                  }}
                ></FilterGameOptionsButton>
                <br />
                {
                  this.state.availableAdversaries.filter((s) => s.include)
                    .length
                }
                /{this.state.availableAdversaries.length} OPTIONS INCLUDED
              </div>
              <div className="col-sm-5 col-md-3 filter-option">
                <FilterGameOptionsButton
                  entityType="scenarios"
                  initialOptions={this.state.availableScenarios}
                  onSave={(newAvailableScenarios) => {
                    const currentState = {
                      ...this.state,
                      availableScenarios: newAvailableScenarios,
                    };
                    const newFilteredOptions = filterAllOptionsWrapper(
                      currentState,
                      false
                    );
                    const newDifficultyRange =
                      getDifficultyRange(newFilteredOptions);
                    this.setState({
                      ...this.state,
                      availableScenarios: newAvailableScenarios,
                      difficultyRange: newDifficultyRange,
                    });
                  }}
                ></FilterGameOptionsButton>
                <br />
                {this.state.availableScenarios.filter((s) => s.include).length}/
                {this.state.availableScenarios.length} OPTIONS INCLUDED
              </div>
            </div>

            <div className="row">
              <DifficultySelector
                difficultyRange={this.state.difficultyRange}
                onChangeRange={this.onChangeSliderRange}
              />
            </div>

            <div className="row randomize-button-container">
              <Button
                className="col-6 randomize-setup-button"
                type="submit"
                disabled={
                  this.state.noValidGame.noMapType ||
                  this.state.noValidGame.notEnoughSpirits ||
                  this.state.noValidGame.notEnoughMaps ||
                  this.state.noValidGame.invalidDifficultyRange
                }
                onClick={() => this.onChangeRandomized(true)}
              >
                Randomize Setup
              </Button>
            </div>
          </div>
        )}

        {this.state.hasResult === true && (
          <ResultsView
            gameSetup={this.state.gameSetup}
            randomizeFunction={(isRandomized) =>
              this.onChangeRandomized(isRandomized)
            }
          />
        )}
      </div>
    );
  }
}
