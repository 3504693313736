import { Range } from "rc-slider";
import "./difficulty-selector.css";

export const DifficultySelector = ({
  difficultyRange,
  onChangeRange,
}: {
  difficultyRange: number[];
  onChangeRange: (range: number[]) => void;
}) => {
  return (
    <div className="col slider">
      Set Difficulty Range
      <div className="row slider-bar">
        <div className="col-10">
          <Range
            min={0}
            max={18}
            marks={{
              0: 0,
              1: 1,
              2: 2,
              3: 3,
              4: 4,
              5: 5,
              6: 6,
              7: 7,
              8: 8,
              9: 9,
              10: 10,
              11: 11,
              12: 12,
              13: 13,
              14: 14,
              15: 15,
              16: 16,
              17: 17,
              18: 18,
            }}
            value={difficultyRange}
            onChange={(rangeValues) => onChangeRange(rangeValues)}
          />
        </div>
      </div>
    </div>
  );
};
