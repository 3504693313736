export const Scenario = ({ scenario }: { scenario: string }) => {
  return (
    <div className="bottom-row-element">
      Scenario
      {scenario !== "NONE" && (
        <div style={{ paddingTop: 20 }}>
          <div>
            <img
              src={"/images/scenarios/" + scenario + ".png"}
              alt={scenario}
              width="150"
            />
          </div>
          <div className="name">{scenario}</div>
        </div>
      )}
      {scenario === "NONE" && <div className="no-name">{scenario}</div>}
    </div>
  );
};
