import { FilterOptionType } from "../../../types/filter-option.type";
import { PlayerSetup } from "../../../types/player-setup.type";

export const notEnoughSpirits = (
  availableSpirits: FilterOptionType[],
  players: PlayerSetup[]
) => {
  if (availableSpirits.filter((s) => s.include).length < players.length) {
    return true;
  } else {
    return false;
  }
};
