import { ADVERSARIES, GAME_TYPE, MAP_TYPE, SCENARIOS } from "../info/constants";
import { GeneralSetup } from "../types/general-setup-type";

const calculateDifficulty = (
  mapType: string,
  gameType: string,
  adversary: string,
  adversaryLevel: number,
  scenario: string
) => {
  let thematicFactor = 0;
  let scenarioDifficulty = 0;
  let adversaryDifficulty = 0;
  if (mapType === "THEMATIC") {
    if (gameType === "BASE") {
      thematicFactor = 3;
    } else if (gameType === "BRANCH & CLAW" || gameType === "JAGGED EARTH") {
      thematicFactor = 1;
    }
  }
  if (adversary !== "NONE") {
    adversaryDifficulty =
      ADVERSARIES.find((o) => o.name === adversary)?.difficulty[
        adversaryLevel
      ] || 0;
  }
  if (scenario) {
    scenarioDifficulty =
      SCENARIOS.find((o) => o.name === scenario)?.difficulty || 0;
  }
  const difficulty = thematicFactor + scenarioDifficulty + adversaryDifficulty;
  return difficulty;
};

export const getAllCombinations = () => {
  let allOptions: GeneralSetup[] = [];
  for (let a = 0; a < ADVERSARIES.length; a++) {
    for (let al = 0; al <= 6; al++) {
      for (let s = 0; s < SCENARIOS.length; s++) {
        for (let mt = 0; mt < MAP_TYPE.length; mt++) {
          for (let gt = 0; gt < GAME_TYPE.length; gt++) {
            allOptions.push({
              adversary: ADVERSARIES[a].name,
              adversaryLevel: al,
              scenario: SCENARIOS[s].name,
              mapType: MAP_TYPE[mt].name,
              gameType: GAME_TYPE[gt].name,
              difficulty: calculateDifficulty(
                MAP_TYPE[mt].name,
                GAME_TYPE[gt].name,
                ADVERSARIES[a].name,
                al,
                SCENARIOS[s].name
              ),
            });
          }
        }
      }
    }
  }
  return allOptions;
};
