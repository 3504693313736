import { GeneralSetup } from "../../../../types/general-setup-type";
import "./adversary-display.css";

export const Adversary = ({ generalSetup }: { generalSetup: GeneralSetup }) => {
  return (
    <div className="bottom-row-element">
      Adversary
      {generalSetup.adversary !== "NONE" && (
        <div className="row adv-flag-array">
          {generalSetup.adversaryLevel === 0 && (
            <div className="col-8">
              <img
                className="adv-l0-flag"
                src={"/images/adversaries/" + generalSetup.adversary + ".png"}
                alt={generalSetup.adversary}
              />
            </div>
          )}
          {generalSetup.adversaryLevel > 0 && (
            <div className="col-8 col-md-10 col-lg-8">
              <div className="row adv-flag-array">
                {Array.apply(0, Array(generalSetup.adversaryLevel)).map(
                  (x, i) => {
                    return (
                      <div className="col-5 col-sm-4 adv-flag-wrapper" key={i}>
                        <img
                          className="adv-flag"
                          src={
                            "/images/adversaries/" +
                            generalSetup.adversary +
                            ".png"
                          }
                          alt={generalSetup.adversary}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
          <div className="row name">
            <div className="col-10">
              {generalSetup.adversary}
              <br></br>Level {generalSetup.adversaryLevel}
              <br></br>
            </div>
          </div>
        </div>
      )}
      {generalSetup.adversary === "NONE" && (
        <div className="no-name">{generalSetup.adversary}</div>
      )}
    </div>
  );
};
