import { FilterOptionType } from "../types/filter-option.type";

export const randomizeSpirits = (
  numPlayers: number,
  spiritList: FilterOptionType[]
) => {
  let selectedSpirits: string[] = [];
  let allSpirits = spiritList.filter((s) => s.include);
  for (let i = 0; i < numPlayers; i++) {
    selectedSpirits.push(
      allSpirits[Math.floor(Math.random() * allSpirits.length)].name
    );
    allSpirits = allSpirits.filter(
      (spirit) => spirit.name !== selectedSpirits[i]
    );
  }
  return selectedSpirits;
};

export const randomizeMaps = (
  numPlayers: number,
  mapType: string,
  mapList: { name: string }[]
) => {
  let maps: string[] = [];
  if (mapType === "BALANCED") {
    let selectedMaps: string[] = [];
    for (let i = 0; i < numPlayers; i++) {
      selectedMaps.push(
        mapList[Math.floor(Math.random() * mapList.length)].name
      );
      mapList = mapList.filter((map) => map.name !== selectedMaps[i]);
      if (numPlayers === 2 && i === 0) {
        if (selectedMaps[i] === "B") {
          mapList = mapList.filter((map) => map.name !== "E");
        } else if (selectedMaps[i] === "E") {
          mapList = mapList.filter((map) => map.name !== "B");
        } else if (selectedMaps[i] === "D") {
          mapList = mapList.filter((map) => map.name !== "F");
        } else if (selectedMaps[i] === "F") {
          mapList = mapList.filter((map) => map.name !== "D");
        }
      }
    }
    maps = selectedMaps;
  } else if (mapType === "THEMATIC") {
    let selectedMaps: string[] = [];
    if (numPlayers === 6) {
      selectedMaps = mapList.map((m) => m.name);
    } else {
      let map1 = mapList[Math.floor(Math.random() * mapList.length)].name;
      if ([1, 2, 3].includes(numPlayers)) {
        selectedMaps.push(map1);
      }
      mapList = mapList.filter((map) => map.name !== map1);
      if (numPlayers === 5) {
        selectedMaps = mapList.map((m) => m.name);
      }
      if ([2, 3, 4].includes(numPlayers)) {
        let map2 = "";
        const eastRegex = /EAST/;
        if (eastRegex.test(map1)) {
          map2 = map1.replace("EAST", "WEST");
        } else {
          map2 = map1.replace("WEST", "EAST");
        }
        if ([2, 3].includes(numPlayers)) {
          selectedMaps.push(map2);
        }
        mapList = mapList.filter((map) => map.name !== map2);
        if (numPlayers === 4) {
          selectedMaps = mapList.map((m) => m.name);
        }
        if (numPlayers === 3) {
          const northRegex = /NORTH/;
          const southRegex = /SOUTH/;
          if (northRegex.test(map1)) {
            mapList = mapList.filter((map) => !southRegex.test(map.name));
          } else if (southRegex.test(map1)) {
            mapList = mapList.filter((map) => !northRegex.test(map.name));
          }
          let map3 = mapList[Math.floor(Math.random() * mapList.length)].name;
          selectedMaps.push(map3);
        }
      }
    }
    maps = selectedMaps;
  }
  return maps;
};
