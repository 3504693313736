import "./error-stripe.css";

const INVALID_GAME_WARNINGS = {
  noMapType: "Invalid game parameters: at least one map type must be selected",
  notEnoughSpirits:
    "Invalid game parameters: more players than playable spirits",
  notEnoughMaps: "Invalid game parameters: more players than available maps",
  invalidDifficultyRange:
    "Difficulty range not compatible with game parameters",
};

export const ErrorStripe = ({
  noMapType,
  notEnoughSpirits,
  notEnoughMaps,
  invalidDifficultyRange,
}: {
  noMapType: boolean;
  notEnoughSpirits: boolean;
  notEnoughMaps: boolean;
  invalidDifficultyRange: boolean;
}) => {
  return (
    <div className="row error-stripe">
      <div className="col error-stripe-col">
        {invalidDifficultyRange === true && (
          <div className="row error-stripe">
            {INVALID_GAME_WARNINGS.invalidDifficultyRange}
          </div>
        )}
        {noMapType === true && (
          <div className="row error-stripe">
            {INVALID_GAME_WARNINGS.noMapType}
          </div>
        )}
        {notEnoughSpirits === true && (
          <div className="row error-stripe">
            {INVALID_GAME_WARNINGS.notEnoughSpirits}
          </div>
        )}
        {notEnoughMaps === true && (
          <div className="row error-stripe">
            {INVALID_GAME_WARNINGS.notEnoughMaps}
          </div>
        )}
      </div>
    </div>
  );
};
