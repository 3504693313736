import { GameSetup } from "../../../../types/game-setup.type";
import { PlayerSetup } from "../../../../types/player-setup.type";

const listOfMaps = (players: PlayerSetup[]) => {
  let numPlayers = players.length;
  return players.map(function (player, i) {
    return (
      <span key={i}>
        {player.board}
        {i < numPlayers - 1 && <span>, </span>}
      </span>
    );
  });
};

export const Maps = ({ gameSetup }: { gameSetup: GameSetup }) => {
  return (
    <div className="bottom-row-element">
      Maps
      <div className="name">
        {gameSetup.generalSetup.mapType}:<br></br>
        {listOfMaps(gameSetup.players)}
      </div>
      <br />
      Difficulty {gameSetup.generalSetup.difficulty}
    </div>
  );
};
