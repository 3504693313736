import { PlayerSetup } from "../../../../types/player-setup.type";
import "./spirits-display.css";

const Spirit = (props: { spirit: string }) => {
  return (
    <div className="col-sm-6 col-lg-4 spirit">
      <div className="row spirit-pic">
        <img
          src={"/images/spirits/" + props.spirit + ".png"}
          alt={props.spirit}
          width="150"
        />
      </div>
      <div className="row spirit-name">{props.spirit}</div>
    </div>
  );
};

const listOfSpirits = (players: PlayerSetup[]) => {
  return players.map(function (player, i) {
    return <Spirit spirit={player.spirit} key={i} />;
  });
};

export const SpiritsContainer = ({ players }: { players: PlayerSetup[] }) => {
  return (
    <div className="row spirits-container">
      <div className="col-10 spirits-container">
        <div className="row">
          <div className="col spirit-header">Spirits</div>
        </div>
        <div className="row spirits-array">{listOfSpirits(players)}</div>
      </div>
    </div>
  );
};
