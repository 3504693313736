import "./map-type-selector.css";

const Checkbox = ({
  name,
  selected,
  onSelect,
}: {
  name: string;
  selected: boolean;
  onSelect: (name: string, newVal: boolean) => void;
}) => {
  return (
    <div className="checkbox">
      <div>
        <input
          className="radio-button"
          type="checkbox"
          checked={selected}
          onChange={(e) => {
            onSelect(name, e.target.checked);
          }}
        />
      </div>
      <div>{name}</div>
    </div>
  );
};

export const MapTypeSelector = ({
  mapTypes,
  includeJEMaps,
  onSelectMapType,
  onSelectJEMaps,
}: {
  mapTypes: { name: string; include: boolean }[];
  includeJEMaps: boolean;
  onSelectMapType: (name: string, newVal: boolean) => void;
  onSelectJEMaps: (newVal: boolean) => void;
}) => {
  return (
    <div className="map-type-selector-component">
      <div className="row radio-header">Include Map Types</div>
      <div className="row map-checkboxes">
        <div className="col-10">
          <div className="row map-type-options">
            {mapTypes.map(function (mt, i) {
              return (
                <div className="col-sm-auto checkbox-and-label" key={i}>
                  <Checkbox
                    name={mt.name}
                    selected={mt.include}
                    onSelect={onSelectMapType}
                  />
                </div>
              );
            })}
            <div className="col-sm-auto checkbox-and-label">
              <div className="checkbox">
                <div>
                  <input
                    className="radio-button"
                    type="checkbox"
                    checked={includeJEMaps}
                    onChange={(e) => {
                      onSelectJEMaps(e.target.checked);
                    }}
                  />
                </div>
                <div>INCLUDE J.E. MAPS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
