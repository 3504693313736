import { GameComponent } from "../../../types/game-component.type";
import { PlayerSetup } from "../../../types/player-setup.type";

export const notEnoughMaps = (
  availableMaps: {
    name: string;
    gameComponent: GameComponent;
    mapType: string;
    include: boolean;
  }[],
  availableMapTypes: { name: string; include: boolean }[],
  players: PlayerSetup[]
) => {
  let numMapTypesAvailable = availableMapTypes.filter(
    (mt) => mt.include
  ).length;
  let numMapsAvailable =
    availableMaps.filter((s) => s.include).length / numMapTypesAvailable;
  if (numMapsAvailable < players.length) {
    return true;
  } else {
    return false;
  }
};
