import { GAME_TYPE } from "../../../../info/constants";
import { GeneralSetup } from "../../../../types/general-setup-type";
import "./game-type-selector.css";

export const GameTypeSelector = ({
  generalSetup,
  onChangeGameType,
}: {
  generalSetup: GeneralSetup;
  onChangeGameType: (gameType: string) => void;
}) => {
  const gameTypeSelector = [];

  for (let i = 0; i < GAME_TYPE.length; i++) {
    gameTypeSelector.push(
      <div className="col-sm-4 radio-selector" key={i}>
        <div>
          <input
            className="radio-button"
            type="radio"
            value={GAME_TYPE[i].name}
            checked={GAME_TYPE[i].name === generalSetup.gameType}
            onChange={() => {
              onChangeGameType(GAME_TYPE[i].name);
            }}
          />
        </div>
        <div>{GAME_TYPE[i].name}</div>
      </div>
    );
  }
  return (
    <div className="game-type-selector-component">
      <div className="row radio-header">Game Rules</div>
      <div className="row game-type-options">
        <div className="col-lg-10 col-sm-8">
          <div className="row">{gameTypeSelector}</div>
        </div>
      </div>
    </div>
  );
};
