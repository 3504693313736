import { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { GameComponent } from "../../../types/game-component.type";
import "./filter-options-modal.css";

type Options = {
  name: string;
  gameComponent: GameComponent;
  include: boolean;
}[];

export const FilterGameOptionsButton = ({
  entityType,
  initialOptions,
  onSave,
}: {
  entityType: "adversaries" | "spirits" | "scenarios";
  initialOptions: Options;
  onSave: (newOptions: Options) => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button
        className="col-8 col-sm-9 filter-options-button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Filter {entityType.charAt(0).toUpperCase() + entityType.slice(1)}
      </Button>
      <Modal show={modalOpen}>
        <FilterGameOptionsModalContent
          entityType={entityType}
          initialOptions={initialOptions}
          onSave={onSave}
          closeModal={() => setModalOpen(false)}
        ></FilterGameOptionsModalContent>
      </Modal>
    </>
  );
};

const FilterGameOptionsModalContent = ({
  entityType,
  initialOptions,
  onSave,
  closeModal,
}: {
  entityType: "adversaries" | "spirits" | "scenarios";
  initialOptions: Options;
  onSave: (newOptions: Options) => void;
  closeModal: () => void;
}) => {
  const [options, setOptions] = useState(initialOptions);

  const setOption = useCallback(
    (i: number, include: boolean) =>
      setOptions((currentOptions: Options) => {
        const newOptions = [...currentOptions];
        newOptions[i] = { ...newOptions[i], include };
        return newOptions;
      }),
    []
  );

  const setAllOptions = useCallback(
    (setAllTrue: boolean) =>
      setOptions((currentOptions: Options) => {
        let newOptions = [...currentOptions];
        newOptions.forEach((o, i) => {
          newOptions[i] = { ...newOptions[i], include: setAllTrue };
        });
        return newOptions;
      }),
    []
  );

  const totalIncluded = useMemo(() => {
    let totalCount = 0;
    for (const option of options) {
      if (option.include) {
        totalCount++;
      }
    }
    return totalCount;
  }, [options]);

  return (
    <div className="filter-options-modal">
      <Modal.Header>
        <Modal.Title id="ModalHeader">
          {entityType.charAt(0).toUpperCase() + entityType.slice(1)} Included in
          Randomizer
        </Modal.Title>
        {totalIncluded === 0 && (
          <div className="nothing-selected-warning">
            At least one option must be selected
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="row select-clear-buttons">
          <Button
            className="col-3 select-all-button"
            onClick={() => setAllOptions(true)}
          >
            Select All
          </Button>
          <Button
            className="col-3 clear-all-button"
            onClick={() => setAllOptions(false)}
          >
            Clear All
          </Button>
        </div>
        <div className="row modal-options">
          <div className="col">
            {options.map((option, i) => {
              const { name, include } = option;
              return (
                <div className="row modal-checkbox-and-label" key={i}>
                  <div className="col-1 modal-checkbox">
                    <input
                      type="checkbox"
                      checked={include}
                      onChange={(e) => setOption(i, e.target.checked)}
                    />
                  </div>
                  {name !== "NONE" && (
                    <div className="col-2 modal-pic">
                      <img
                        src={"/images/" + entityType + "/" + name + ".png"}
                        alt={name}
                        width="55"
                      />
                    </div>
                  )}
                  <div className="col modal-label">
                    {name !== "NONE" && <>{name}</>}
                    {name === "NONE" && <>INCLUDE 'NONE' AS OPTION</>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-button"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-primary"
          disabled={totalIncluded === 0}
          onClick={() => {
            onSave(options);
            closeModal();
          }}
        >
          Apply
        </Button>
      </Modal.Footer>
    </div>
  );
};
