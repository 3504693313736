import { FilterOptionType } from "../types/filter-option.type";
import { GeneralSetup } from "../types/general-setup-type";

export const getDifficultyRange = (filteredOptions: GeneralSetup[]) => {
  let diffArray = filteredOptions.map((fo, i) => {
    return fo.difficulty;
  });
  let maxDifficulty = Math.max(...diffArray);
  let minDifficulty = Math.min(...diffArray);
  return [minDifficulty, maxDifficulty];
};

export const updateBasedOnGameComponents = (
  options: FilterOptionType[],
  gameComponent: string,
  isIncluded: boolean
) => {
  options.forEach((x) => {
    if (gameComponent === x.gameComponent) {
      x.include = isIncluded;
    }
  });
  return options;
};
