import { FilterOptionType } from "../types/filter-option.type";
import { GameSetup } from "../types/game-setup.type";
import { GeneralSetup } from "../types/general-setup-type";

export const filterAllOptionsWrapper = (
  currentState: {
    hasResult: boolean;
    gameSetup: GameSetup;
    availableMapTypes: { name: string; include: boolean }[];
    availableGameComponents: { name: string; include: boolean }[];
    availableSpirits: FilterOptionType[];
    availableScenarios: FilterOptionType[];
    availableAdversaries: FilterOptionType[];
    difficultyRange: number[];
    noValidGame: {
      noMapType: boolean;
      notEnoughSpirits: boolean;
      invalidDifficultyRange: boolean;
    };
    validOptions: GeneralSetup[];
  },
  useDifficultyRange: boolean
) => {
  return filterAllOptions(
    currentState.availableMapTypes,
    currentState.availableScenarios,
    currentState.availableAdversaries,
    currentState.gameSetup.generalSetup.gameType,
    useDifficultyRange ? currentState.difficultyRange : null,
    currentState.validOptions
  );
};

const filterAllOptions = (
  availableMapTypes: { name: string; include: boolean }[],
  availableScenarios: { name: string; include: boolean }[],
  availableAdversaries: { name: string; include: boolean }[],
  gameType: string,
  difficultyRange: number[] | null,
  startingOptions: GeneralSetup[]
) => {
  let filteredAds = availableAdversaries
    .filter((ad) => ad.include)
    .map((adv, i) => {
      return adv.name;
    });
  let filteredScenarios = availableScenarios
    .filter((s) => s.include)
    .map((s, i) => {
      return s.name;
    });
  let filteredMapTypes = availableMapTypes
    .filter((m) => m.include)
    .map((m, i) => {
      return m.name;
    });
  let filteredOptions = startingOptions.filter(
    (o) =>
      o.gameType === gameType &&
      filteredAds.includes(o.adversary) &&
      filteredScenarios.includes(o.scenario) &&
      filteredMapTypes.includes(o.mapType) &&
      (difficultyRange !== null
        ? o.difficulty >= difficultyRange[0] &&
          o.difficulty <= difficultyRange[1]
        : true)
  );
  return filteredOptions;
};
