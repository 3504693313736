import Button from "react-bootstrap/esm/Button";
import { GameSetup } from "../../../types/game-setup.type";
import { Adversary } from "./elements/adversary-display.component";
import { Maps } from "./elements/maps-difficulty-display.component";
import { Scenario } from "./elements/scenario-display.component";
import { SpiritsContainer } from "./elements/spirits-display.component";
import "./results-view.css";

export const ResultsView = ({
  gameSetup,
  randomizeFunction,
}: {
  gameSetup: GameSetup;
  randomizeFunction: (isRandomized: boolean) => void;
}) => {
  return (
    <div>
      <SpiritsContainer players={gameSetup.players} />
      <div className="row bottom-row-container">
        <div className="col-10">
          <div className="row">
            <div className="col-md-4 bottom-row-element">
              <Adversary generalSetup={gameSetup.generalSetup} />
            </div>
            <div className="col-md-4 d-md-none bottom-row-element">
              <Scenario scenario={gameSetup.generalSetup.scenario} />
            </div>
            <div className="col-md-4 bottom-row-element">
              <Maps gameSetup={gameSetup} />
            </div>
            <div className="col-md-4 d-none d-md-block bottom-row-element">
              <Scenario scenario={gameSetup.generalSetup.scenario} />
            </div>
          </div>
        </div>
      </div>
      <div className="row button-container">
        <Button
          className="try-again-button"
          type="submit"
          onClick={() => randomizeFunction(true)}
        >
          Try Again
        </Button>
      </div>
      <div className="row button-container">
        <Button
          className="reset-button"
          type="submit"
          onClick={() => randomizeFunction(false)}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
