import { PlayerSetup } from "../../../../types/player-setup.type";
import "./num-players-selector.css";

export const PlayerNumberSelector = ({
  players,
  onChangeFunction,
}: {
  players: PlayerSetup[];
  onChangeFunction: (num: number) => void;
}) => {
  const numPlayersSelector = [];

  for (let i = 1; i <= 6; i++) {
    numPlayersSelector.push(
      <div className="col-6 col-sm-4 col-md-2 radio-selector" key={i}>
        <div>
          <input
            className="radio-button"
            type="radio"
            value={i}
            checked={i === players.length}
            onChange={() => {
              onChangeFunction(i);
            }}
          />
        </div>
        {i}
      </div>
    );
  }
  return (
    <div className="col num-players-selector-component">
      <div className="row radio-header">Number of Spirits</div>
      <div className="row num-players-selector-options">
        <div className="col-8 col-sm-6 col-lg-4">
          <div className="row">{numPlayersSelector}</div>
        </div>
      </div>
    </div>
  );
};
