import { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import landscapeLogo from "./images/spirit-island.jpg";
import Randomizer from "./components/randomizer/game-randomizer.component";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="container-xl app-class">
        <Router>
          <nav className="navbar navbar-expand-lg navbar-light bg-light row">
            <div className="col-sm-auto">
              <a href="/" target="_blank">
                <div className="d-block d-sm-none spacer"> </div>
                <img
                  src={landscapeLogo}
                  alt="SPIRIT ISLAND GAME TRACKER"
                  width="150px"
                />
              </a>
            </div>

            <div className="col justify-content-sm-left justify-content-lg-center">
              <Link
                to="/"
                className="title justify-content-sm-left justify-content-lg-center"
              >
                Spirit Island Game Setup Randomizer
              </Link>
            </div>
            <div className="col-sm-auto">
              <a href="/" target="_blank" className="d-none d-lg-block">
                <img
                  src={landscapeLogo}
                  alt="SPIRIT ISLAND GAME TRACKER"
                  width="150px"
                />
              </a>
            </div>
          </nav>
          <br />
          <Route path="/" component={Randomizer} />
        </Router>
      </div>
    );
  }
}

export default App;
