export const GAME_COMPONENT_OPTIONS = [
  {
    name: "BASE GAME",
  },
  {
    name: "BRANCH & CLAW",
  },
  {
    name: "JAGGED EARTH",
  },
  {
    name: "PROMO PACK 1",
  },
  {
    name: "PROMO PACK 2",
  },
  {
    name: "HORIZONS OF SPIRIT ISLAND",
  },
];

export const ADVERSARIES = [
  {
    name: "NONE",
    difficulty: [0, 0, 0, 0, 0, 0, 0],
    gameComponent: "",
  },
  {
    name: "THE HABSBURGH MONARCHY (LIVESTOCK COLONY)",
    difficulty: [2, 3, 5, 6, 8, 9, 10],
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "THE KINGDOM OF BRANDENBURG-PRUSSIA",
    difficulty: [1, 2, 4, 6, 7, 9, 10],
    gameComponent: "BASE GAME",
  },
  {
    name: "THE KINGDOM OF ENGLAND",
    difficulty: [1, 3, 4, 6, 7, 9, 10],
    gameComponent: "BASE GAME",
  },
  {
    name: "THE KINGDOM OF FRANCE (PLANTATION COLONY)",
    difficulty: [2, 3, 5, 7, 8, 9, 10],
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "THE KINGDOM OF SCOTLAND",
    difficulty: [1, 3, 4, 6, 7, 8, 10],
    gameComponent: "PROMO PACK 2",
  },
  {
    name: "THE KINGDOM OF SWEDEN",
    difficulty: [1, 2, 3, 5, 6, 7, 8],
    gameComponent: "BASE GAME",
  },
  {
    name: "THE TSARDOM OF RUSSIA",
    difficulty: [1, 3, 4, 6, 7, 9, 11],
    gameComponent: "JAGGED EARTH",
  },
];

export const SCENARIOS = [
  {
    name: "NONE",
    difficulty: 0,
    gameComponent: "",
  },
  {
    name: "A DIVERSITY OF SPIRITS",
    difficulty: 0,
    gameComponent: "PROMO PACK 2",
  },
  {
    name: "BLITZ",
    difficulty: 0,
    gameComponent: "BASE GAME",
  },
  {
    name: "DAHAN INSURRECTION",
    difficulty: 4,
    gameComponent: "BASE GAME",
  },
  {
    name: "DESPICABLE THEFT",
    difficulty: 2,
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "ELEMENTAL INVOCATION",
    difficulty: 1,
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "GUARD THE ISLE'S HEART",
    difficulty: 0,
    gameComponent: "BASE GAME",
  },
  {
    name: "POWERS LONG FORGOTTEN",
    difficulty: 1,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "RITUALS OF TERROR",
    difficulty: 3,
    gameComponent: "BASE GAME",
  },
  {
    name: "RITUALS OF THE DESTROYING FLAME",
    difficulty: 3,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "SECOND WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "THIRD WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "FOURTH WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "FIFTH WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "SIXTH WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "SEVENTH WAVE",
    difficulty: 0,
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "THE GREAT RIVER",
    difficulty: 3,
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "VARIED TERRAINS",
    difficulty: 2,
    gameComponent: "PROMO PACK 2",
  },
  {
    name: "WARD THE SHORES",
    difficulty: 2,
    gameComponent: "BRANCH & CLAW",
  },
];

export const GAME_TYPE = [
  {
    name: "BASE",
  },
  {
    name: "BRANCH & CLAW",
  },
  {
    name: "JAGGED EARTH",
  },
];

export const MAP_TYPE = [
  {
    name: "BALANCED",
  },
  {
    name: "THEMATIC",
  },
];

export const MAPS = [
  {
    name: "A",
    gameComponent: "BASE GAME",
    mapType: "BALANCED",
  },
  {
    name: "B",
    gameComponent: "BASE GAME",
    mapType: "BALANCED",
  },
  {
    name: "C",
    gameComponent: "BASE GAME",
    mapType: "BALANCED",
  },
  {
    name: "D",
    gameComponent: "BASE GAME",
    mapType: "BALANCED",
  },
  {
    name: "E",
    gameComponent: "JAGGED EARTH",
    mapType: "BALANCED",
  },
  {
    name: "F",
    gameComponent: "JAGGED EARTH",
    mapType: "BALANCED",
  },
  {
    name: "EAST",
    gameComponent: "BASE GAME",
    mapType: "THEMATIC",
  },
  {
    name: "WEST",
    gameComponent: "BASE GAME",
    mapType: "THEMATIC",
  },
  {
    name: "NORTHEAST",
    gameComponent: "BASE GAME",
    mapType: "THEMATIC",
  },
  {
    name: "NORTHWEST",
    gameComponent: "BASE GAME",
    mapType: "THEMATIC",
  },
  {
    name: "SOUTHEAST",
    gameComponent: "JAGGED EARTH",
    mapType: "THEMATIC",
  },
  {
    name: "SOUTHWEST",
    gameComponent: "JAGGED EARTH",
    mapType: "THEMATIC",
  },
];

export const SPIRITS = [
  {
    name: "A SPREAD OF RAMPANT GREEN",
    complexity: "MODERATE",
    gameComponent: "BASE GAME",
  },
  {
    name: "BRINGER OF DREAMS AND NIGHTMARES",
    complexity: "HIGH",
    gameComponent: "BASE GAME",
  },
  {
    name: "DEVOURING TEETH LURK UNDERFOOT",
    complexity: "LOW",
    gameComponent: "HORIZONS OF SPIRIT ISLAND",
  },
  {
    name: "DOWNPOUR DRENCHES THE WORLD",
    complexity: "HIGH",
    gameComponent: "PROMO PACK 2",
  },
  {
    name: "EYES WATCH FROM THE TREES",
    complexity: "LOW",
    gameComponent: "HORIZONS OF SPIRIT ISLAND",
  },
  {
    name: "FATHOMLESS MUD OF THE SWAMP",
    complexity: "LOW",
    gameComponent: "HORIZONS OF SPIRIT ISLAND",
  },
  {
    name: "FINDER OF PATHS UNSEEN",
    complexity: "VERY HIGH",
    gameComponent: "PROMO PACK 2",
  },
  {
    name: "FRACTURED DAYS SPLIT THE SKY",
    complexity: "VERY HIGH",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "GRINNING TRICKSTER STIRS UP TROUBLE",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "HEART OF THE WILDFIRE",
    complexity: "HIGH",
    gameComponent: "PROMO PACK 1",
  },
  {
    name: "KEEPER OF THE FORBIDDEN WILDS",
    complexity: "MODERATE",
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "LIGHTNING'S SWIFT STRIKE",
    complexity: "LOW",
    gameComponent: "BASE GAME",
  },
  {
    name: "LURE OF THE DEEP WILDERNESS",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "MANY MINDS MOVE AS ONE",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "OCEAN'S HUNGRY GRASP",
    complexity: "HIGH",
    gameComponent: "BASE GAME",
  },
  {
    name: "RISING HEAT OF STONE AND SAND",
    complexity: "LOW",
    gameComponent: "HORIZONS OF SPIRIT ISLAND",
  },
  {
    name: "RIVER SURGES IN SUNLIGHT",
    complexity: "LOW",
    gameComponent: "BASE GAME",
  },
  {
    name: "SERPENT SLUMBERING BENEATH THE ISLAND",
    complexity: "HIGH",
    gameComponent: "PROMO PACK 1",
  },
  {
    name: "SHADOWS FLICKER LIKE FLAME",
    complexity: "LOW",
    gameComponent: "BASE GAME",
  },
  {
    name: "SHARP FANGS BEHIND THE LEAVES",
    complexity: "MODERATE",
    gameComponent: "BRANCH & CLAW",
  },
  {
    name: "SHIFTING MEMORY OF AGES",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "SHROUDS OF SILENT MIST",
    complexity: "HIGH",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "STARLIGHT SEEKS ITS FORM",
    complexity: "VERY HIGH",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "STONE'S UNYIELDING DEFIANCE",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "SUN-BRIGHT WHIRLWIND",
    complexity: "LOW",
    gameComponent: "HORIZONS OF SPIRIT ISLAND",
  },
  {
    name: "THUNDERSPEAKER",
    complexity: "MODERATE",
    gameComponent: "BASE GAME",
  },
  {
    name: "VENGEANCE AS A BURNING PLAGUE",
    complexity: "HIGH",
    gameComponent: "JAGGED EARTH",
  },
  {
    name: "VITAL STRENGTH OF THE EARTH",
    complexity: "LOW",
    gameComponent: "BASE GAME",
  },
  {
    name: "VOLCANO LOOMING HIGH",
    complexity: "MODERATE",
    gameComponent: "JAGGED EARTH",
  },
];
